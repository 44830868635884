@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";  

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

video {
    max-width: 100%;
    height: auto;
}

html {
    width: 100%;
    background-color: #ffffff;
}

h1 {
    font-size: 34pt;
}

h2 {
    font-size: 26pt;
}

h3 {
    font-size: 18pt;
}

h4 {
  font-size: 14pt;
}

.center-div {
  text-align:center;
  width: 50%;
  margin: 0 auto
}

.mat-form-field-flex {
  background-color: #ffffff !important;
}

/* Prevents horizontal scrollbar from appearing on left nav side bar */
/* Gotta account for the left-hand padding in child menu items */
.menu-child a.mat-list-item {
  box-sizing: border-box;
}

.route-content {
  padding-left: 10px;
  padding-right: 10px;
}

.upload-image-box {
  border: solid 1px #3F51B5;
  padding: 10px 5px 10px 5px;
  margin: 0px 5px 0px 0px;
  border-radius: 5px;
}

.label {
  text-align: left;
  margin-bottom: 10px;
  font-weight: 500;
  margin-left: 4px;
  font-size: 12pt;
}

.vertical-form-field {
    margin-bottom: 15px;
}

.no-label-field .mat-form-field-infix {
  border-top: none;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.stepper-navigation {
  margin-top:30px;
}

.mat-vertical-stepper-content {
  margin-top:15px;
}

.error-spree {
  font-weight: 500;
  font-size: 12pt;
  line-height: 16pt;
  color: red;
}

.error-spree-header {
  font-size: 14pt;
  font-weight: 600;
  color:red;
}

.mat-step-icon-invalid {
    background-color: red !important;
}

.list-country-flags {
  max-height: 100px !important;
}

/* new added CSS */

.btn-blue {background-color: dodgerblue; color: white; font-weight: bold;}

.mat-form-field-underline {
  background-color: black;
}

.example-form {
  min-width: 10px;
  max-width: 10px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

